export interface ExistsObject {
    viewName: string,
    binding:string,
    distinctColumn:string,
    distinctTargetColumn:string,
    whereClause:string,
    column:string,
    targetColumn:string,

}
